import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import faviconPng from '../assets/favicon.png'
import faviconIco from '../assets/favicon.ico'

/**
 * An SEO handler getting data from each page and assigning in a Helmet.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @param {String} props.description
 * @param {String} props.image
 * @param {String} props.title
 * @returns {FunctionComponent}
 */
const SEO = ({ children, title, description, image }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  return (
    <Helmet titleTemplate={title}>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href={faviconIco} />
      <link rel="icon" type="image/png" href={faviconPng} />
      <meta name="description" content={description} />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <title>{title}</title>

      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta propery="og:site_name" content={title} key="ogsitename" />
      <meta
        property="og:image"
        content={`https://butikfestival.com/${image}`}
      />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site_name" content={title} />
      <meta
        name="twitter:image"
        content={`https://butikfestival.com/${image}`}
      />

      {children}
    </Helmet>
  )
}

export default SEO
