import React from 'react'

import { theme } from '../../constants/theme'

const ArrowUpRight = ({ color = theme.textPrimary, className }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.265 12.1242C23.4172 12.3415 23.4767 12.6102 23.4307 12.8714L21.9572 21.2278C21.8613 21.7717 21.3427 22.1349 20.7988 22.039C20.2549 21.9431 19.8917 21.4244 19.9876 20.8805L21.0419 14.9017L11.5513 21.547C11.0989 21.8638 10.4754 21.7538 10.1586 21.3014C9.84183 20.849 9.95177 20.2255 10.4042 19.9087L19.8947 13.2634L13.9159 12.2091C13.372 12.1132 13.0088 11.5946 13.1047 11.0507C13.2006 10.5068 13.7193 10.1436 14.2632 10.2395L22.6195 11.713C22.8807 11.759 23.1129 11.9069 23.265 12.1242Z"
      fill={color}
    />
  </svg>
)

export default ArrowUpRight
